import React, { FunctionComponent } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import styled from "styled-components";

const OrderedList = styled.ol`
  position: sticky;
  top: 100px;
  list-style: none;
  padding: 0;

  & > li {
    position: relative;
    height: 54px;
    padding: 6px 12px 6px 32px;
    line-height: 20px;
    font-size: 15px;
    font-weight: 400;
    counter-increment: step;
    display: flex;
    align-items: start;
    justify-content: start;
    margin-bottom: 3px;
  }

  & > li:hover > a {
    color: #C23A2C;
  }

  & > li:before {
    padding: 6px 0;
    font-weight: bold;
    position: absolute;
    top: 0;
    left: 0;
    color: #333;
    content: counter(step);
  }
`;

const TrendingList: FunctionComponent = () => {
  const results = useStaticQuery(graphql`
    query {
      allPosts: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(posts)/.*\\\\.md$/" } }
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              path
            }
          }
        }
      }

      pageViews: allPageViews(limit: 15) {
        nodes {
          id
          totalCount
        }
      }
    }
  `);
  
  const trendingIndex = results.pageViews.nodes.reduce((acc, node) => {
    acc[node.id] = node.totalCount;
    return acc;
  }, {});

  const trendingPosts = results.allPosts.edges
    .filter(({ node }) => trendingIndex[node.frontmatter.path || '/'] > 0)
    .map(({ node }) => ({
      ...node,
      totalCount: trendingIndex[node.frontmatter.path]
    }))
    .sort((a,b) => b.totalCount - a.totalCount)
    .slice(0, 10);
  
  return (
    <>
      <OrderedList>
      <h3>Trending</h3>
          {trendingPosts.map((post, index) => (
            <li key={index}>
                <Link to={post.frontmatter.path}>
                    {post.frontmatter.title}
                </Link>
            </li>
          ))}
      </OrderedList>
    </>
  );
};

export default TrendingList;
